import React from "react"
import PropTypes from "prop-types"

import Header from "./header.component"
import Footer from "./footer.component"

const Layout = ({ className, children, showHeader, pathname }) => {
  return (
    <>
      {showHeader && <Header pathname={pathname} />}
      <div className={className}>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool
}

Layout.defaultProps = {
  showHeader: true
}

export default Layout
