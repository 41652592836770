import React from "react"
import { Link } from "gatsby"
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaRegCalendarAlt,
  FaExternalLinkAlt,
  FaYoutube,
  FaFacebookF,
  // FaInstagram,
} from "react-icons/fa"

import imgLogoFullLight from "../images/logo-full-light.png"

import "./footer.styles.scss"

const Footer = () => (
  <>
    <section className="locations">
      <div className="container py-6">
        <div className="columns is-centered">
          <div className="column is-one-third">
            <div className="content is-size-6">
              <span className="has-text-weight-bold is-block pb-4">
                Studios
              </span>
              <div className="is-flex">
                <span className="icon pt-3">
                  <FaMapMarkerAlt size="1.6rem" />
                </span>
                <p className="pl-2">
                  Murrysville
                  <br />
                  <span className="has-text-weight-bold">
                    Newlonsburg Music Academy
                  </span>
                  <br />
                  4600 Old William Penn Highway
                  <br />
                  Murrysville, PA 15668
                </p>
              </div>
              <div className="is-flex mt-4">
                <span className="icon pt-3">
                  <FaMapMarkerAlt size="1.6rem" />
                </span>
                <p className="pl-2">
                  Wexford
                  <br />
                  <span className="has-text-weight-bold">
                    PYCO School of Music
                  </span>
                  <br />
                  150 Lake Dr, Suite 103
                  <br />
                  Wexford, PA 15090{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="content is-size-6">
              <span className="has-text-weight-bold is-block pb-4">
                Contact Info
              </span>
              <p className="is-flex is-align-items-center pb-4">
                <span className="icon">
                  <FaEnvelope size="1.6rem" />
                </span>
                <a
                  href="mailto:lessons@paraskyflutestudios.com"
                  className="is-link is-block pl-2"
                >
                  lessons@paraskyflutestudios.com
                </a>
              </p>
              <p className="is-flex is-align-items-center pb-4">
                <span className="icon">
                  <FaPhoneAlt size="1.6rem" />
                </span>
                <span className="is-block pl-2">(412) 475-0163</span>
              </p>
              <p className="is-flex is-align-items-center pb-4">
                <span className="icon">
                  <FaRegCalendarAlt size="1.6rem" />
                </span>
                <span className="is-block pl-2">
                  <a
                    href="https://calendar.google.com/calendar/u/0/embed?src=51nppqc524vleu6h2358fu28t0@group.calendar.google.com&ctz=America/New_York"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live Studio Calendar <FaExternalLinkAlt size=".8rem" />
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer className="footer has-background-dark has-text-white">
      <div className="container">
        <div className="footer__links is-flex is-justify-content-center has-text-centered is-flex-wrap-wrap mt-4">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/lessons">Lessons</Link>
          <Link to="/resources">Studio Resources</Link>
          <Link to="/contact">Contact</Link>
          <a href="https://danparasky.com">
            danparasky.com <FaExternalLinkAlt size=".8rem" />
          </a>
        </div>
        <div className="footer__aside">
          <div className="footer__social is-flex is-justify-content-center mt-5">
            <a
              className="button is-outlined py-5 px-3 mx-5"
              href="https://www.youtube.com/Paraskyflutestudios"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube size="1.8rem" />
            </a>
            <a
              className="button is-outlined py-5 px-3 mx-5"
              href="https://www.facebook.com/paraskyflutestudios/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF size="1.8rem" />
            </a>
            {/* <a
              className="button is-outlined py-5 px-3 mx-5"
              href="#"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size="1.8rem" />
            </a> */}
          </div>
          <div className="footer__logo is-flex is-justify-content-center mt-6">
            <figure className="image">
              <img src={imgLogoFullLight} alt="logo" />
            </figure>
          </div>
          <div className="footer__copyright is-flex is-align-items-center is-justify-content-center mt-6">
            <span className="copyright__container has-text-grey-lighter is-size-6">
              © {(new Date().getFullYear())} Parasky Flute Studios
              <span className="copyright__div">|</span>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </span>
          </div>
          <div className="is-flex is-justify-content-center mt-4">
            <div className="content is-size-7 has-text-grey-lighter">
              Site development by:{" "}
              <a href="https://justinspegele.com" target="_blank" rel="noreferrer" className=" has-text-grey-light">
                justinspegele.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
