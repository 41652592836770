import React, { useState } from "react"
import { Link } from "gatsby"
import { FaExternalLinkAlt } from 'react-icons/fa'

import imgLogoLight from "../images/logo-nav-light.png"
import imgLogoDark from "../images/logo-nav-dark.png"

import './header.styles.scss'

const Header = ({ theme, pathname }) => {
  const [burgerNavActive, setBurgerNavActive] = useState(false)

  const handleSetBurgerNav = () => setBurgerNavActive(!burgerNavActive)

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      handleSetBurgerNav()
    }
  }

  return (
    <header className="header mt-4 mb-4">
      <div className="container">
        <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img
                className="logo"
                src={theme === "light" ? imgLogoDark : imgLogoLight}
                alt="Parasky Flute Studios"
              />
            </Link>
            <span
              role="button"
              tabIndex={0}
              className={`navbar-burger is-size-1 ${burgerNavActive ? 'is-active' : ''} ${theme === 'dark' ? 'has-text-white' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={handleSetBurgerNav}
              onKeyDown={handleKeyDown}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </span>
          </div>
          <div className={`navbar-menu ${burgerNavActive ? 'is-active' : ''} ${theme === 'light' ? '' : 'navbar-menu--on-dark'}`}>
            <div className="navbar-end">
              <Link to="/" className="navbar-item is-hidden-desktop">
                Home
              </Link>
              <Link to="/about" className={`navbar-item ${pathname === "/about" ? "is-active" : ""}`}>
                About
              </Link>
              <Link to="/lessons" className={`navbar-item ${pathname === "/lessons" ? "is-active" : ""}`}>
                Lessons
              </Link>
              <Link to="/resources" className={`navbar-item ${pathname === "/resources" ? "is-active" : ""}`}>
                Studio Resources
              </Link>
              <Link to="/contact" className={`navbar-item ${pathname === "/contact" ? "is-active" : ""}`}>
                Contact
              </Link>
              <a className="navbar-item" href="https://danparasky.com" target="_blank" rel="noreferrer">
                danparasky.com <FaExternalLinkAlt size=".8rem" />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

Header.defaultProps = {
  theme: "light",
}

export default Header
